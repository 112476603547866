.create-news{
    // text-align: center;
    h3{
        font-size: 24px;
        margin-top: 40px;
    }
    .create-content{
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        >p{
            margin-top: 30px;
        }
        .news-title{
            &::before{
                content: '*';
                color: red;
                margin-right: 5px;
            }
        }
        .news-desc{
            &::before{
                content: '*';
                color: red;
                margin-right: 5px;
            }
        }
        .check-lang{
            display: flex;
            .check-lang-title{
                line-height: 40px;
                margin-right: 40px;
            }
            .ant-tabs{
                .ant-tabs-nav{
                    &::before{
                        border: none;
                    }
                }
            }
        }
        .upload-img{
            .upload-img-title{

            }
            .ant-upload-wrapper{
                margin-top: 10px;
                .ant-upload-list{
                    .ant-upload{
                        width: 123px;
                    }
                }
            }
        }
    }
}



