.user-management{
    .user_title{
        display: flex;
        justify-content: space-between;
        background-color: white;
        margin: 40px 0;
        padding: 20px 0;
        h3{
            text-align: left;
            color: rgb(35, 123, 216);
            margin: 0;
            margin-left: 10px;
        }
        .search{
            display: flex;
            margin-right: -1600px;
        }
        .addUser{
            margin-right: 250px;
        }
    }
    .news-list{
        .ant-table-wrapper{
            .ant-table{
                .ant-table-content{
                    .ant-table-tbody{
                        .ant-table-row{
                            .ant-table-cell{
                                .ant-select-disabled{
                                    .ant-select-selector{
                                        cursor: default;
                                        .ant-select-selection-item{
                                            cursor: default;
                                            color: rgba($color: #000000, $alpha: .8);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ant-modal{
    .ant-modal-content{
        .ant-modal-body{
            .add-modal{
                >p{
                    margin-top: 40px;
                }
                .add-btn{
                    margin-top: 50px;
                    text-align: center;
                    .ant-btn{
                        width: 120px;
                    }
                }
            }
        }
    }
}