.user_title{
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin: 40px 0;
    padding: 20px 0;
    h3{
        text-align: left;
        color: rgb(35, 123, 216);
        margin: 0;
        margin-left: 10px;
    }
    .search{
        display: flex;
        margin-right: -1600px;
    }
    .addUser{
        margin-right: 250px;
    }
}

.language{
    text-align: left;
    margin-bottom: 30px;
    .ant-select{
        width: 120px;
    }
}
.banner-list{
}

.edit-modal{
    width: 800px !important;
    .ant-modal-content{
        width: 800px;

        .ant-modal-body{
            .create-content{
                width: auto;
                >.ant-input{
                    width: 600px;
                }
            }
        }
    }
}

.ant-table-wrapper{
    .ant-spin-container{
        .ant-pagination{
            text-align: center;
            display: block;
        }
    }
}
