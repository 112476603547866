.app {
    font-size: 1.6rem;
    @media screen and (max-width: 960px) {
      font-size: 1.4rem;
    }
    .aside {
      position: fixed;
      left: 0;
      height: 100vh;
      overflow-y: auto;
      
    }
    .logo {
      height: 3rem;
      margin: 1rem;
      box-sizing: border-box;
      border-radius: 1rem;
      // padding: 0 1rem;
      text-align: center;
      img {
        height: 100%;
        margin: 0 auto;
      }
    }
  
    .header {
      background-color: #fff;
      padding: 0 1.5rem;
      display: flex;
      justify-content: space-between;
      .right {
        display: flex;
        align-items: center;
      }
    }
  
    .content {
      padding: 10px 18px;
      width: auto !important;
    }
  
    .footer {
      text-align: center;
    }
    .ant-menu-item{
      margin-bottom: 10px !important;
    }
}
.modify-pwd{
  .ant-modal-confirm-btns{
    display: none;
  }
}