.person-center{
    width: 700px;
    margin: 0 auto;
    text-align: left;
    .person-head{
        padding: 20px 0;
        border-bottom: 1px solid rgb(234, 230, 230);
        margin-bottom: 50px;
        span{
            font-size: 16px;
            font-weight: 700;
        }
        .person-return{
            margin-left: 600px;
        }
    }
    .person-item{
        margin-bottom: 40px;
        .upload-img{
            display: flex;
            margin-top: 10px;
            align-items: center;
            .noImg{
                width: 178px;
                height: 104px;
                border: 1px dashed #D4D4D4;
                text-align: center;
                line-height: 104px;
                font-size: 32px;
                color: rgba($color: #000000, $alpha: .4);
                margin-right: 10px;
            }
            .ant-upload-wrapper{
                .ant-upload-list{
                    .ant-upload-select{
                            width: 123px;
                            height: 32px;
                    }
                }
            }
        }
    }
    .person-passwd{
        >p{
            span{

            }
            .passwd-change{
                color: rgb(36, 130, 232);
                margin-left: 600px;
                cursor: pointer;
            }
        }
    }
}

.passwdModal{
    .ant-modal-content{
        .ant-modal-body{

            >p{
                margin-top: 30px;
                span{
                    color: red;
                }
            }
            .btn{
                text-align: center;
                margin-top: 40px;
            }
        }
    }
}


