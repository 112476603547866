h3{
    font-size: 24px;
    margin: 40px 0 ;
}
.create-content{
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    .upload-div{
        .addText{
            width: 104px;
            height: 104px;
            border: 1px dashed #ccc;
            text-align: center;
            line-height: 104px;
            color: #ccc;
            font-size: 40px;
        }
        .ant-btn{
            margin-top: 15px;
        }
    }
    .news-title,.news-desc{
        &::before{
            content: '*';
            color: red;
            margin-right: 3px;
        }
    }
    >p{
        margin-top: 30px;
    }
    >.ant-input{
        width: 800px;
    }
    .check-lang{
        display: flex;
        .check-lang-title{
            line-height: 40px;
            margin-right: 40px;
        }
        .ant-tabs{
            .ant-tabs-nav{
                &::before{
                    border: none;
                }
            }
        }
    }
    .upload-img{
        .upload-img-title{
            &::before{
                content: '*';
                margin-right: 3px;
                color: red;
            }
        }
        .noImg{
            width: 178px;
            height: 104px;
            border: 1px dashed #D4D4D4;
            text-align: center;
            line-height: 104px;
            font-size: 32px;
            color: rgba($color: #000000, $alpha: .4);
            margin-top: 10px;
        }
        .ant-upload-wrapper{
            margin-top: 10px;
            .ant-upload-list{
                .ant-upload{
                    width: 123px;
                    height: 32px;
                    padding-top: 2px;
                }
            }
        }
    }
    .news-title,.news-desc{
        
    }
    .must-title,.must-desc{
        &::before{
            content: '*';
            margin-right: 3px;
            color: red;
        }
    }
    .upload-file{
        &::before{
            content: '*';
            margin-right: 3px;
            color: red;
        }
        margin-top: 30px;
        .ant-upload-wrapper{
            .ant-upload{
                .upload-title{
                    
                }
                .ant-btn{
                    width: 200px;
                    height: 50px;
                    p{
                        color: rgb(161, 153, 153);
                    }
                }
            }
        }
    }
    .create-editor{
        margin-top: 30px;
        >p{
            &::before{
                content: '*';
                color: red;
                margin-right: 3px;
            }
        }
    }
    .special-user{
        margin-top: 30px;
        margin-bottom: 240px;
        .antd-div{
            display: flex;
            position: relative;
            .ant-select{
                margin-right: 50px;
                .ant-select-dropdown{
                    top: 36px !important;
                    z-index: 999;
                }
            }
            .ant-input{
                margin-right: 20px;
            }
            .addUser-box{
                position: relative;
                .add-user{

                    .ant-input{
                        width: 250px !important;
                    }
                }
            }
            .special-list{
                width: 250px;
                height: 200px;
                border: 1px solid #ccc;
                position: absolute;
                left: 0;
                top: 32px;
                background-color: white;
                z-index: 99;
                overflow: scroll;
                &::-webkit-scrollbar {
                    display: none; /* Chrome Safari */
                }
                -ms-overflow-style: none;
                scrollbar-width: none;
                display: flex;
                flex-direction: column;
                // display: none;
                >div{
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    margin-top: 10px;
                    >p{
                        width: 120px;
                        line-height: 32px;
                        text-align: left;
                        margin-bottom: 0;
                        &:last-child{
                            width: 32px; 
                            text-align: center;
                            span{
                                cursor: pointer;
                                border: 1px solid #ccc;
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
            .showList{
                opacity: 1;
            }
        }
    }
    .create-link{
        margin-top: 30px;
        .link-title{

            >span{
                display: inline-block;
                width: 90px;
                border: 1px solid #ccc;
                height: 36px;
                line-height: 36px;
                text-align: center;
                cursor: pointer;
                caret-color: rgba(0, 0, 0,0);
            }
        }
        .check-link{
            background-color: white;
            color: rgb(79, 140, 224);

        }
        .ant-input{
            margin-top: 10px;
        }
        .ant-select{
            margin-top: 10px;
        }
    }
    .Weights{
        margin-top: 24px;
        >p{
            &::before{
                content: '*';
                margin-right: 3px;
                color: red;
            }
        }
    }
    .create-btn{
        text-align: center;
        margin-top: 100px;
        .ant-btn{
            width: 200px;
            height: 45px;
        }
    }
}

.special-dropdown{
    .ant-dropdown-menu{
        min-height: 200px;
    }
}

.user{
    cursor: pointer;
    span{
        display: inline-block;
        width: 50px;
        margin-left: 10px;
        &:last-child{
            width: 18px;
            height: 18px;
            line-height: 18px;
            border: 1px solid #ccc;
            text-align: center;
            font-size: 18px;
        }
    }
    .username{
        width: 80px;
    }

}

.ant-select-dropdown{
    // min-height: 100px;
    height: 200px;
    overflow-y: hidden;
}

.iconModal{
    width: 1080px !important;
    .ant-modal-content{
        width: 1080px;
        .ant-modal-close{
            display: none;
        }
        .ant-modal-body{
            .upload-img{
                .upload-img-title{
                    display: none;
                }
                .noImg{
                    display: none;
                }
                .productIcon{
                    height: 54px;
                    line-height: 54px;
                    margin-bottom: 14px;
                    border-bottom: 1px solid rgba($color: #000000, $alpha: .06);
                }
                .iconList{
                    padding-bottom: 52px;
                    border-bottom: 1px solid rgba($color: #000000, $alpha: .06);
                    margin-bottom: 9px;
                    >div{
                        position: relative;
                        width: 104px;
                        height: 104px;
                        line-height: 104px;
                        text-align: center;
                        background: #FAFAFA;
                        display: inline-block;
                        margin: 26px 22px 0 0;
                        cursor: pointer;
                        >img{
                            width: 52px;
                            height: 52px;
                        }
                    }
                    .checkImg{
                        border: 1px solid #1890FF;
                    }
                }
            }
            .confirmBox{
                text-align: right;
                .ant-btn-primary{
                    margin-left: 8px;
                }
            }
        }
    }
}